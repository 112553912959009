import { _, doAction } from '@situation/setdesign.util';
import { setSearchTerm, getSearchTerm } from '../models/notesModel';
import { APPLY_SEARCH_FILTER } from '../models/events';

const searchInputController = ($input) => {
    const onInputChange = _.throttle(
        () => {
            const newSearchTerm = $input.val();
            if (newSearchTerm !== getSearchTerm()) {
                setSearchTerm(newSearchTerm);
                doAction(APPLY_SEARCH_FILTER);
            }
        },
        250,
        { leading: false, trailing: true },
    );
    $input.on('input', onInputChange);
};

export default searchInputController;
