import {
    $, addAction, doAction, LAYOUT,
} from '@situation/setdesign.util';
import { APPLY_SEARCH_FILTER, LOAD_MORE, RESET_MASONRY } from '../models/events';
import searchInputController from './searchInputController';
import {
    setNotes, setGroupLength, getGroupLength, filterNotes,
} from '../models/notesModel';
import pagination from '../views/pagination';
import masonryLayout from '../views/masonryLayout';
import resizeHandler from './resizeHandler';

const notesController = ($notesContainer) => {
    setNotes($notesContainer.find('.notes__entry').clone());
    setGroupLength(parseInt($notesContainer.data('group'), 10) || getGroupLength());
    searchInputController($('.notes-search-filter'));
    pagination($notesContainer, $notesContainer.find('.notes__more .button'));
    masonryLayout($notesContainer.find('.notes__inner'));
    addAction(APPLY_SEARCH_FILTER, () => {
        $notesContainer.find('.notes__inner').html(filterNotes());
        doAction('refreshLazyLoad', $notesContainer);
        doAction(RESET_MASONRY);
        doAction(LOAD_MORE);
    });

    doAction(LOAD_MORE);
    addAction(LAYOUT, resizeHandler);
};

export default notesController;
