import { doAction } from '@situation/setdesign.util';
import { setIsMasonry } from '../models/notesModel';
import { RESET_MASONRY } from '../models/events';

const BREAKPOINT = 576;
let oldViewportWidth = 0;

const resizeHandler = () => {
    const newViewportWidth = SetDesign.viewport.width();
    if (oldViewportWidth >= BREAKPOINT && newViewportWidth < BREAKPOINT) {
        setIsMasonry(false);
        doAction(RESET_MASONRY);
    } else if (oldViewportWidth < BREAKPOINT && newViewportWidth >= BREAKPOINT) {
        setIsMasonry(true);
        doAction(RESET_MASONRY);
    }

    oldViewportWidth = newViewportWidth;
};

export default resizeHandler;
