import { $, addAction, INIT } from '@situation/setdesign.util';

const doc = window.document;

const copyToClipboard = (str) => {
    const el = doc.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    doc.body.appendChild(el);
    el.select();
    doc.execCommand('copy');
    doc.body.removeChild(el);
};

const handleCopyLinkClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const ACTIVE_CLASS = 'active';
    const $el = $(e.currentTarget);
    $el.addClass(ACTIVE_CLASS);
    $el.find('.copy-text').text('Link Copied');
    const link = $el.prop('href');
    copyToClipboard(link);
    setTimeout(() => {
        $el.removeClass(ACTIVE_CLASS);
        $el.find('.copy-text').text('Copy Link');
    }, 2000);
};

const showModalAction = ($modal) => {
    if ($modal.find('.note-card-content').length) {
        const id = $modal.attr('id');
        window.history.replaceState(null, null, `${window.document.location.pathname}#${id}`);
    }
};

const hideModalAction = () => {
    window.history.replaceState(null, null, window.document.location.pathname);
};

addAction(INIT, () => $('.note-card-footer__copy-link').on('click', handleCopyLinkClick));
addAction('showModal', showModalAction);
addAction('hideModal', hideModalAction);
