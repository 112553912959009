import { $ } from '@situation/setdesign.util';
import notesController from './notes/controllers/notesController';

const initializeNotes = () => {
    const $notesContainer = $('.notes');
    if ($notesContainer.length) {
        notesController($notesContainer);
    }
};

$(window).on('load', initializeNotes);
