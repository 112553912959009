import { addAction, LAYOUT } from '@situation/setdesign.util';
import Masonry from 'masonry-layout';
import { RESET_MASONRY, LOAD_MORE } from '../models/events';
import { getIsMasonry } from '../models/notesModel';

const masonryLayout = ($container) => {
    let masonry = false;

    const resetMasonry = () => {
        if (masonry) {
            masonry.destroy();
            masonry = false;
        }
        if (getIsMasonry() && !masonry) {
            masonry = new Masonry($container[0], {
                itemSelector: '.notes__entry',
                resize: false,
                transitionDuration: 0,
                initLayout: false,
            });
        }
    };

    const refreshMasonry = () => {
        if (getIsMasonry() && masonry) {
            masonry.layout();
        }
    };

    addAction(RESET_MASONRY, resetMasonry);
    addAction(LOAD_MORE, refreshMasonry);
    addAction(LAYOUT, refreshMasonry, 20);
};

export default masonryLayout;
