import { $, addAction } from '@situation/setdesign.util';

const getAspectRatio = ($embed) => {
    const width = $embed.attr('width');
    const height = $embed.attr('height');
    return !width || !height || width.endsWith('%') || height.endsWith('%')
        ? false
        : parseFloat(height) / parseFloat(width);
};

const showModalAction = ($modal) => {
    const $embedContainer = $modal.find('.embed-responsive');
    const embedCode = $embedContainer.data('embed');
    const $embedLoader = $('<div id="embed-loader"></div>').html(embedCode);
    const $embedCode = $embedLoader.find('iframe');
    if ($embedCode.length) {
        const aspectRatio = getAspectRatio($embedCode);
        if (aspectRatio > 1) {
            $embedContainer
                .parents('.note-card-content__embed')
                .addClass('note-card-content__embed--vertical');
        }

        $embedContainer
            .css('padding-top', aspectRatio ? `${aspectRatio * 100}%` : '')
            .addClass('embed-responsive--force')
            .append($embedCode);
    } else if (embedCode) {
        $embedContainer
            .parents('.note-card-content__embed')
            .addClass('note-card-content__embed--vertical');

        $embedContainer.html(embedCode);
    }
};

const hideModalAction = ($modal) => {
    $modal.find('.embed-responsive').empty();
};

addAction('showModal', showModalAction);
addAction('hideModal', hideModalAction);
