import { $, addAction, doAction } from '@situation/setdesign.util';
import { getGroupLength } from '../models/notesModel';
import { LOAD_MORE } from '../models/events';

const DELAYFACTOR = 30;

const pagination = ($container, $trigger) => {
    const groupLength = getGroupLength();

    const loadMore = () => {
        const $notes = $container.find('.notes__entry--hide');
        $trigger.parent().toggleClass('notes__more--hide', $notes.length <= groupLength);
        const $newNotes = $notes.slice(0, groupLength);
        $newNotes.removeClass('notes__entry--hide');
        setTimeout(() => {
            $newNotes
                .each((i, el) => {
                    $(el).css('transition-delay', `${(i * DELAYFACTOR) / 1000}s`);
                })
                .addClass('notes__entry--show');
        }, 50);
    };
    if (!$trigger.data('listening')) {
        $trigger.data('listening', true);
        addAction(LOAD_MORE, loadMore);
        $trigger.on('click', (e) => {
            e.preventDefault();
            doAction(LOAD_MORE);
        });
    }
};

export default pagination;
