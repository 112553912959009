import { $ } from '@situation/setdesign.util';

const state = {
    allNotes: null,
    filteredNotes: null,
    searchTerm: '',
    groupLength: 12,
    isMasonry: false,
};

const setNotes = (notes) => {
    state.allNotes = notes;
    state.filteredNotes = notes;
};

const setGroupLength = (length) => {
    state.groupLength = length;
};

const setSearchTerm = (term) => {
    state.searchTerm = term.toLowerCase();
};

const setIsMasonry = (isMasonry) => {
    state.isMasonry = isMasonry;
};

const getNotes = () => state.allNotes;
const getFilteredNotes = () => state.filteredNotes;
const getGroupLength = () => state.groupLength;
const getSearchTerm = () => state.searchTerm;
const getIsMasonry = () => state.isMasonry;
const filterMethod = (i, n) => $(n).text().toLowerCase().includes(state.searchTerm);

const filterNotes = () => {
    if (!state.searchTerm) {
        state.filteredNotes = state.allNotes;
    } else {
        state.filteredNotes = state.allNotes.filter(filterMethod);
    }
    return state.filteredNotes.removeClass('notes__entry--show').addClass('notes__entry--hide');
};

export {
    setNotes,
    setGroupLength,
    setSearchTerm,
    setIsMasonry,
    getNotes,
    getFilteredNotes,
    getGroupLength,
    getSearchTerm,
    getIsMasonry,
    filterNotes,
};
